import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container } from "react-bootstrap";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { slotsGames } from "../../../../lib/data/slotsGames.data";
import BackIcon from "../../../../assets/svg/BackIcon";

const Slots = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <div>
      <HeaderAfterLogin />

      <main className="main">
        <Container>
          <div className="slots-section">
            <div className="pagesHeading">
              <h4>Slots</h4>
              <a href="#" className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </a>
            </div>

            <div className="games-section">
              <ul>
                {Object.values(slotsGames).map((item) => (
                  <li key={item.code}>
                    <a href={item.redirectUrl}>
                      <img
                        src={item.imgUrl}
                        alt={item.alt}
                        className="exchangeGames-img-body"
                      />
                      <div className="casino_overlay">
                        <img src={item.playImg} />
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default Slots;
