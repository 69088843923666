import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BottomNav from "../../../../containers/BottomNav";

import BackIcon from "../../../../assets/svg/BackIcon";
import Ezugi from "../../../../assets/images/provider/Ezugi.jpg";
import Evolution from "../../../../assets/images/provider/Evolution.jpg";
import Vivo from "../../../../assets/images/provider/Vivo.jpg";
import XPG from "../../../../assets/images/provider/Xpg.jpg";
import Supernowa from "../../../../assets/images/provider/Supernowa.jpg";
import Qtech from "../../../../assets/images/provider/Qtech.jpg";
import Slots from "../../../../assets/images/provider/Slots.jpg";
import WorldCasino from "../../../../assets/images/provider/Worldcasino.jpg";
import VirtualSports from "../../../../assets/images/provider/virtualsports.jpg";

const LiveDealerPublic = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <main className="main">
        <div className="container">
          <div className="live-deal-section">
            <div className="pagesHeading">
              <h2 className="sectionTitle border-0">Casino</h2>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <ul>
              <li>
                <a href="/casino/ezugi">
                  <img src={Ezugi} alt="ezugi" />
                </a>
              </li>
              <li>
                <a href="/casino/evolution">
                  <img src={Evolution} alt="evolution" />
                </a>
              </li>
              <li>
                <a href="/casino/vivo">
                  <img src={Vivo} alt="vivo" />
                </a>
              </li>
              <li>
                <a href="/casino/xpg">
                  <img src={XPG} alt="xpg" />
                </a>
              </li>
              <li>
                <a href="/casino/supernowa">
                  <img src={Supernowa} alt="supernowa" />
                </a>
              </li>
              <li>
                <a href="/casino/qtech">
                  <img src={Qtech} alt="qtech" />
                </a>
              </li>
              <li>
                <a href="/slots">
                  <img src={Slots} alt="slots" />
                </a>
              </li>
              <li>
                <a href="/casino/worldcasino">
                  <img src={WorldCasino} alt="worldcasino" />
                </a>
              </li>
              <li>
                <a href="/exchange/Cricket">
                  <img src={VirtualSports} alt="virtual sports" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
      <BottomNav />
    </>
  );
};

export default LiveDealerPublic;
