import React from "react";
import { useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { auraGames } from "../../lib/data/auraGames.data";
import { slotsGames } from "../../lib/data/slotsGames.data";
import { casinoGamesData } from "../../lib/data/casinoGames.data";

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);
  const totalGames = {
    // ...spribeData,
    ...casinoGamesData.indianCard,
    ...casinoGamesData.popularGames,
    ...casinoGamesData.rightBarGames,
    ...casinoGamesData.verticalSlider,
    ...slotsGames,
    ...auraGames.improvedExperience,
    ...auraGames.cardGames,
    ...auraGames.virtualCardGames,
    ...auraGames.instantGames,
  };

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(totalGames)?.filter((item) => {
        if (typeof item !== "string") {
          return item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1;
        }
        return false;
      });
      setFilteredData(filteredValue);
      console.log("Search Data", filteredValue);
    } else {
      setSearchText(false);
    }
  };
  console.log("FilterData", filteredData);

  return (
    <>
      <div className="searchGames" onClick={handleShow}>
        <BiSearch />
        {/* <p>Search games</p> */}
      </div>

      <Modal show={show} onHide={handleClose} className="gameSearchModal">
        <Modal.Header closeButton>
          <Modal.Title>Search your favourite games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3 search-icon">
            <Form.Control
              placeholder="Search"
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              autoFocus
            />
          </InputGroup>
          <div className="game-list">
            {filteredData &&
              filteredData.map((item) => (
                <a href={item.redirectUrl}>
                  {item.imgUrl ? <img src={item.imgUrl} /> : item.img}
                  <span>{item.name}</span>
                </a>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamesSearchModal;
