import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import logo from "../assets/images/logo.png";
import Betfair from "../assets/images/provider/Betfair.jpg";
import EvolutionGames from "../assets/images/provider/provider-logo-evolution.png";
import EzugiGames from "../assets/images/provider/provider-logo-ezugi.png";
import QtechGames from "../assets/images/provider/provider-logo-Qtech.png";
import SupernowaGames from "../assets/images/provider/provider-logo-supernowa.jpg";
import VirtualSports from "../assets/images/provider/provider-logo-virtualSports.jpg";
import VIVOGames from "../assets/images/provider/provider-logo-vivo.png";
import WorldCasino from "../assets/images/provider/provider-logo-worldCasino.jpg";
import XPGGames from "../assets/images/provider/provider-logo-xpg.jpg";

import binance from "../assets/images/payments/new/Binance.png";
import pmicon from "../assets/images/payments/new/Pm.png";
import therthnt from "../assets/images/payments/new/Tether.png";
import Bitcoin from "../assets/images/payments/new/Bitcoin.png";
import Phonepay from "../assets/images/payments/new/phonepe-logo-icon.png";
import Paytm from "../assets/images/payments/new/Paytm.png";
import GooglaPay from "../assets/images/payments/new/Gpay.png";
import UPIcon from "../assets/images/payments/new/Upi.png";

import Calling1 from "../assets/images/icons/phone.png";
import Whatapps from "../assets/images/icons/whatsapp.png";
import Telegram from "../assets/images/icons/telegram.png";
import Mail from "../assets/images/icons/mail.png";

import FbIconImg from "../assets/images/icons/Facebook.svg";
import InstaIconImg from "../assets/images/icons/Instagram.svg";
import TelegramImg from "../assets/images/icons/Telegram.svg";
import WhatsaPPImg from "../assets/images/icons/Whatsapp.svg";

import Email from "../assets/images/icons/email.png";
import Call from "../assets/images/icons/call.png";
import Whatsap from "../assets/images/icons/whatsap.png";
import Age18 from "../assets/images/icons/agePlus.png";
import BottomNav from "./BottomNav";

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();

  return (
    <>
      <div className="SocialMediaSection">
        <Container>
          <ul>
            <li>
              <a href={`tel://${appDetails?.PHONE}`}>
                <img src={Calling1} alt="Calling icon" />
                <span>+91</span>
              </a>
            </li>
            <li>
              <a href="">
                <img src={Mail} alt="Calling icon" />
                <span></span>
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatapps} alt="Calling icon" />
                <span>Whatsapp</span>
              </a>
            </li>
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Calling icon" />
                <span>Telegram</span>
              </a>
            </li>
          </ul>
        </Container>
      </div>

      <footer className="footer">
        <Container>
          <div className="bestOnline">
            <div>
              <div>
                <h5>ASIA'S BEST ONLINE</h5>
                <h3>SPORT EXCHANGE & CASINO</h3>
              </div>
            </div>
            <a href="/termsandconditions" className="tcApply">
              T&C'S Apply
            </a>
          </div>

          <div className="contacts">
            <Row>
              <Col xs={12}>
                <div>
                  <h5>Connect with us:</h5>
                  <ul className="connectUs">
                    <li>
                      <span>
                        <img src={Email} alt="" width={18} />
                      </span>
                      <a href=""></a>
                    </li>
                    <li>
                      <span>
                        <img src={Call} alt="" width={18} />
                      </span>
                      <a href={`tel://${appDetails?.PHONE}`}>+91 </a>
                    </li>
                    <li>
                      <span>
                        <img src={Whatsap} alt="" width={18} />
                      </span>
                      <a
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        +91
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12}>
                <div className="mt-4">
                  {/* <h5>Follow us:</h5> */}
                  <ul className="followUs">
                    <li>
                      <h5>Reach us:</h5>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.INSTATGRAM != ""
                            ? appDetails?.INSTATGRAM
                            : "#"
                        }
                        target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                      >
                        <img src={InstaIconImg} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.FACEBOOK != ""
                            ? appDetails?.FACEBOOK
                            : "#"
                        }
                        target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                      >
                        <img src={FbIconImg} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "#"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <img src={TelegramImg} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsaPPImg} />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>

          <div className="footer-links">
            <Row className="justify-content-center">
              <Col xl={9}>
                <Row>
                  <Col xs={6} md={3}>
                    <h6>COMPANY</h6>
                    <ul>
                      {/* <li>
                        <a href="javascript:void(0)">About Us </a>
                      </li> */}
                      <li>
                        <a href="/contact">Contact Us</a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} md={3}>
                    <h6>ONLINE CASINO GAMES</h6>
                    <ul>
                      <li>
                        <a href="/casino/ezgevo-evo-roulette">Roulette</a>
                      </li>
                      <li>
                        <a href="/casino/ezgevo-speed-vip-blackjack-m">
                          Blackjack
                        </a>
                      </li>
                      <li>
                        <a href="/casino/ezgevo-speed-baccarat-v">Baccarat</a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} md={3}>
                    <h6>SECURITY & PRIVACY</h6>
                    <ul>
                      <li>
                        <a href="/privacypolicy">Privacy Policy</a>
                      </li>

                      <li>
                        <a href="/ResponsibleGaming">Responsible Gaming</a>
                      </li>

                      <li>
                        <a href="/fairplay">Fair Play</a>
                      </li>
                      <li>
                        <a href="/gamerules">Games Rules</a>
                      </li>
                      <li>
                        <a href="/termsandcondition">Terms and Conditions</a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} md={3}>
                    <h6>LIVE CASINO</h6>
                    <ul>
                      <li>
                        <a href="/casino/ezg-auto-roulette">Live Roulette</a>
                      </li>
                      <li>
                        <a href="/casino/ezg-unlimited-blackjack">
                          Live Blackjack
                        </a>
                      </li>
                      <li>
                        <a href="/casino/ezg-casino-holdem">
                          Live Casino Hold'em
                        </a>
                      </li>
                      <li>
                        <a href="/casino/ezgevo-three-card-poker">
                          Live 3 Card Poker
                        </a>
                      </li>
                      <li>
                        <a href="/casino/ezg-baccarat">Live Baccarat</a>
                      </li>
                      <li>
                        <a href="/casino/ezgevo-ultimate-texas-holdem">
                          Live Ultimate Texas Hold'em
                        </a>
                      </li>
                      <li>
                        <a href="/casino/ezgevo-dream-catcher">Dream Catcher</a>
                      </li>
                      <li>
                        <a href="/casino/ezgevo-crazy-time">Crazy Time</a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="sectionDivide">
            <div className="singleSection">
              <div className="disclaimer">
                <div className="disText">
                  <h5>Disclaimer</h5>
                  <p>
                    This casino game is for entertainment purposes only, and it
                    is only for 18+ years old. Casino games are legitimate and
                    keep the players safe. You do not win anything, and constant
                    practice at the social casino game does not guarantee future
                    success at casino games.
                  </p>
                </div>
                <div className="age18">
                  <img src={Age18} alt="age 18+" width={70} />
                </div>
              </div>
            </div>

            <div className="singleSection">
              <div className="providers_logo">
                <h5>Gaming Partner</h5>
                <ul>
                  <li>
                    <img src={Betfair} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={EvolutionGames} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={EzugiGames} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={QtechGames} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={SupernowaGames} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={VirtualSports} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={VIVOGames} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={WorldCasino} alt="Providers Logo" />
                  </li>
                  <li>
                    <img src={XPGGames} alt="Providers Logo" />
                  </li>
                </ul>
              </div>
              <div className="paymentspartner">
                <h5>Payments Partners</h5>
                <ul>
                  <li>
                    <img src={UPIcon} />
                  </li>
                  <li>
                    <img src={GooglaPay} />
                  </li>
                  <li>
                    <img src={Paytm} />
                  </li>
                  <li>
                    <img src={Phonepay} />
                  </li>
                  <li>
                    <img src={Bitcoin} />
                  </li>
                  <li>
                    <img src={therthnt} />
                  </li>
                  <li>
                    <img src={pmicon} />
                  </li>
                  <li>
                    <img src={binance} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>

        <BottomNav />
      </footer>
    </>
  );
};

export default Footer;
