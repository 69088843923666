import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, Offcanvas, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { AiOutlineSearch } from "react-icons/ai";
import { CustomView, isMobile } from "react-device-detect";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
// import EyeIcon from "../assets/svg/EyeIcon";
import logo from "../assets/images/logo.png";
import MenuIcon from "../assets/images/menu-icon.png";
import NewsIcon from "../assets/images/news-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import WhatsaPPImg from "../assets/images/icons/whatsApp-icon.svg";

import { getNotifications } from "../redux/app/actions";

const Header = () => {
  console.log("Mobile", isMobile);
  // console.log("Mobile check");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [show1, setShow1] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showToggle, setShowToggle] = useState(true);

  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  const handleShow1 = () => setShow1(!show1);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "sports",
    "public-sports",
    "live-dealer",
    "evolution",
    "aviator",
    "sign-in",
    "/",
    "betby",
  ];
  useEffect(() => {
    const matchedValue = headerPages.find((f) => {
      if (location?.pathname == "/public-sports") {
        return f === location?.pathname.split("/")?.[1];
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    loadNotifications();
    return () => {};
  }, []);
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <header className="header header_bfrlgn">
      <div className="topHead">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                >
                  <img src={MenuIcon} alt="User Menu Icon" width={25} />
                </Button>

                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>

                <div className="ms-2 ms-md-4">
                  <GamesSearchModal />
                </div>

                <div className="header-left mx-lg-auto d-none d-xl-block">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="d-none"
                  />
                  <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                    <Nav className="me-auto">
                      {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                      <Nav.Link
                        href="/sports"
                        className={
                          activePage == "sports" || activePage === "/"
                            ? "active"
                            : ""
                        }
                      >
                        Home
                      </Nav.Link>
                      <Nav.Link
                        href="/sign-in"
                        className={activePage == "" ? "active" : ""}
                      >
                        Exchange
                      </Nav.Link>
                      <Nav.Link
                        href="/live-dealer-public"
                        className={activePage == "live-dealer" ? "active" : ""}
                      >
                        Casino
                      </Nav.Link>
                      <Nav.Link
                        href="/sign-in"
                        className={activePage == "sign-in" ? "" : ""}
                      >
                        Ezugi
                      </Nav.Link>
                      <Nav.Link
                        href="/sign-in"
                        className={activePage == "sign-in" ? "" : ""}
                      >
                        Evolution
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/vivo"
                        className={activePage == "sign-in" ? "" : ""}
                      >
                        Aviator
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </div>

                <div className="headerRight ms-auto ms-xl-0">
                  <div className="theme-btn me-2">
                    <button type="button" className="btn" onClick={toggleClass}>
                      <img src={SunLight} alt="sun" className="light_theme" />
                      <img src={MoonLight} alt="moon" className="dark_theme" />
                    </button>
                  </div>

                  <ul>
                    <li className="wtsApp-btn">
                      <a
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsaPPImg} />
                      </a>
                    </li>
                  </ul>

                  <Button
                    variant="primary"
                    // onClick={() => {
                    //   navigate("/sign-in");
                    // }}
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    className="login_btn me-2"
                  >
                    Log In
                  </Button>
                  <Button
                    variant="primary"
                    // onClick={() => {
                    //   navigate("/mobile-number");
                    // }}

                    onClick={() => {
                      navigate("/mobile-number");
                    }}
                    className="signup_btn"
                  >
                    Register
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {(websiteNotifications.length && showNotifications && (
          <div className="Announcement d-none">
            <marquee>
              {" "}
              {websiteNotifications.map((notf, idx) => (
                <label>{notf?.content}</label>
              ))}
            </marquee>
          </div>
        )) ||
          null}
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      {showAuthModals.mobileNumber && (
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.verifyNumber && (
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={() =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.register && (
        <RegisterModal
          show={showAuthModals.register}
          handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
          handleModalShow={handleModalShow}
        />
      )}

      <ForgotPasswords
        show={showAuthModals.forgotpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />
      <ResetPassword
        show={showAuthModals.resetpassword}
        handleClose={() =>
          handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD)
        }
        handleModalShow={handleModalShow}
      />

      {show1 && <MobLeftbar className="d-block d-md-none" />}
    </header>
  );
};

export default Header;
