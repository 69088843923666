import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/games-icon/home-icon.png";
import CasinoIcon from "../assets/images/games-icon/casino-icon.png";
import InplayIcon from "../assets/images/games-icon/inplay-icon.png";
import aviator from "../assets/images/games-icon/aviator.png";

import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import horseracingIcon from "../assets/images/games-icon/horseracing-icon.png";
import BasketballIcon from "../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "../assets/images/games-icon/icehockey-icon.png";
import TableTennisIcon from "../assets/images/games-icon/table-tennis-icon.png";
import Badminton from "../assets/images/games-icon/badminton-icon.png";
import ArcheryIcon from "../assets/images/games-icon/archery-icon.png";
import MotorSportIcon from "../assets/images/games-icon/motor-sport-icon.png";
import GreyhoundIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";

import WalletIcon from "../assets/images/WalletIcon.png";
import UserIcon from "../assets/images/user-icon.svg";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import LogoutIconImg from "../assets/images/logout.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";

import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";

const MobLeftbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  const [isClassAdded, setIsClassAdded] = useState(false);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        {isAuth && (
          <>
            <div className="d-flex profilemobsection">
              <div className="profile-img">
                <img src={UserIcon} alt="User Icon" />
              </div>
              <div className="user-info">
                <Link className="user_name" to="/profile">
                  {user?.mstruserid}
                </Link>
                <p>{user?.mobileno}</p>
              </div>
            </div>
            <div className="container upperbalnace">
              <div className="d-flex align-items-center justify-content-between  mb-3">
                <div className="balance">
                  <img src={WalletIcon} alt="wallet" />
                  Balance
                </div>
                <div className="balance-amount"> INR {wallet?.balance}</div>
              </div>

              <div className="row">
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/gatewaylist");
                  }}
                >
                  <Button variant="primary" className="btn_primary w-100">
                    Deposit
                  </Button>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/bonus");
                  }}
                >
                  <Button variant="" className="btn_withdraw mt-2 w-100">
                    Bonus
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/mybets");
                  }}
                >
                  <Button variant="" className="btn_withdraw  mt-2 w-100">
                    My Bets
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        <ul>
          <li className="games_link">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/live-dealer");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino" />
                  <span>Live Casino</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <h6>Sports</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Inplay", {
                      state: { selectedTab: "1" },
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay" />
                  <span>In-play</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Cricket", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Soccer", {
                      state: { selectedTab: "3" },
                    });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Tennis", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Basketball", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={BasketballIcon} alt="Basketball Icon" />
                  <span>Basketball</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Horse%20Racing", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={horseracingIcon} alt="horseracing Icon" />
                  <span>Horseracing</span>
                </div>
              </li>

              <li>
                <div
                  onClick={() => {
                    navigate("/exchange/Politics", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={PoliticsIcon} alt="Politics Icon" />
                  <span>Politics</span>
                </div>
              </li>
            </ul>
          </li>

          {isAuth && (
            <li className="games_link">
              <h6>My Account</h6>
              <ul className="account-menu">
                <li>
                  <div>
                    <a href="/mybets">
                      <img src={BetIcon} alt="my bet" />
                      <span>My Bets</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div>
                    <a href="/bonus" className="img_size">
                      <img src={BonusImg} />
                      <span className="bonus-space">Bonus</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div>
                    <a href="/referral" className="img_size">
                      <img src={refferal} />

                      <span className="bonus-space">Referral</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div>
                    <a href="/cashier">
                      <img src={AcStatement} alt="account statement" />
                      <span>Account Statement</span>
                    </a>
                  </div>
                </li>
                <li onClick={() => dispatch(logoutUser())}>
                  <div>
                    <a href="/">
                      {/* <LogoutIcon /> */}
                      <img src={LogoutIconImg} />
                      <span>Sign Out</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="theme-btn">
                    <buton type="button" className="btn" onClick={toggleClass}>
                      <img src={SunLight} alt="sun" className="light_theme" />
                      <img src={MoonLight} alt="moon" className="dark_theme" />
                    </buton>
                  </div>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MobLeftbar;
